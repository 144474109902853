body {
  font-family: var(--font-family);
}

.LoginContainer {
  margin-top: 5rem;
}

.LoginBox {
  max-width: 500px;
}

